import { FC, useEffect, useState } from 'react';

import { I18n, Locale } from '@common/application';
import { SitecoreRouteProps } from '@containers';
import { LoadingPage } from '@custom-components/native/LoadingPage';
import { Link } from '@dxp-next';
import { LinkComponentProvider } from '@link';
import {
  DC_Repositories_Base_Enumerations_BusinessUnit,
  DC_Repositories_Base_Enumerations_Label,
} from '@monorepo-types/dc';
import { TokenData, useAuthToken } from '@native-auth/hooks/token';
import { client } from '@sitecore/client/browser';
import { fetchPlaceholder } from '@sitecore/client/browser';
import { ClientResponse } from '@sitecore/client/types';
import { ComponentsDefinition, PlaceholderProvider, SitecoreContext } from '@sitecore/common';
import { getItemFromUrl } from '@sitecore/common/util/native';
import { LayoutServiceData, RouteData } from '@sitecore/types/lib';

interface Props {
  components: ComponentsDefinition;
  layoutDataMap?: Record<string, LayoutServiceData | null>;
  config: {
    businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
    label: DC_Repositories_Base_Enumerations_Label;
    name: string;
  };
  i18n: I18n;
  pageNotFoundComponent?: React.ReactNode;
  layout: (route: RouteData) => React.ReactNode;
}

function addTokenDataToSitecoreContext(layoutData: LayoutServiceData, tokenData: TokenData) {
  return {
    ...(layoutData ?? {}),
    sitecore: {
      ...(layoutData?.sitecore ?? {}),
      context: {
        ...(layoutData?.sitecore?.context ?? {}),
        customerId: tokenData?.customerId,
        accessToken: tokenData?.accessToken,
        accountId: 1,
      },
    },
  };
}
const NativeSitecoreRouteProviders: FC<
  Omit<SitecoreRouteProps, 'name' | 'i18n' | 'path' | 'otherBasePaths' | 'businessUnit' | 'label'>
> = ({ components, layoutData, children }) => {
  const tokenData = useAuthToken();

  return (
    <SitecoreContext
      components={components}
      layoutData={tokenData && layoutData ? addTokenDataToSitecoreContext(layoutData, tokenData) : layoutData}>
      {children}
    </SitecoreContext>
  );
};

function useRequestSitecoreData(
  locale: Locale,
  item?: string,
  layoutDataMap?: Record<string, LayoutServiceData | null>,
) {
  const [sitecoreResponse, setSitecoreResponse] = useState<ClientResponse | undefined>();

  useEffect(() => {
    if (!item) {
      return;
    }
    const layoutData = layoutDataMap ? layoutDataMap[item] : undefined;
    if (layoutData) {
      setSitecoreResponse({
        statusCode: 200,
        layoutData,
      });
      return;
    }
    const fetchData = async () => {
      setSitecoreResponse(undefined);
      try {
        const data = await client({ path: item, locale: locale });
        setSitecoreResponse(data);
      } catch {
        setSitecoreResponse({ statusCode: 404, layoutData: null });
      }
    };
    fetchData();
  }, [item, layoutDataMap, locale]);

  return sitecoreResponse;
}

export const NativeSitecoreRouteContainer = ({
  components,
  i18n,
  layoutDataMap,
  layout,
  pageNotFoundComponent,
}: Props) => {
  const tokenData = useAuthToken();
  const item = getItemFromUrl();
  const sitecoreResponse = useRequestSitecoreData(i18n.locale, item, layoutDataMap);

  if (!sitecoreResponse || !sitecoreResponse?.layoutData === undefined) {
    return <LoadingPage isLoading />;
  }

  if (sitecoreResponse.statusCode === 404 || !sitecoreResponse?.layoutData?.sitecore?.route || !item) {
    return pageNotFoundComponent ? <div>{pageNotFoundComponent}</div> : null;
  }

  return (
    <NativeSitecoreRouteProviders layoutData={sitecoreResponse.layoutData} components={components}>
      <PlaceholderProvider fetchPlaceholder={fetchPlaceholder} idToken={tokenData?.accessToken}>
        <LinkComponentProvider component={Link}>
          {layout(sitecoreResponse.layoutData.sitecore.route)}
        </LinkComponentProvider>
      </PlaceholderProvider>
    </NativeSitecoreRouteProviders>
  );
};
