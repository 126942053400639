import { Browser } from 'ionic-appauth/lib';

import inAppBrowser from '@native-in-app-browser/index';

export class AuthBrowser extends Browser {
  public closeWindow() {
    inAppBrowser.closeWindow();
    this.onCloseFunction();
  }

  public showWindow(targetUrl: string) {
    return inAppBrowser.showWindow(`${targetUrl}&inApp=true`, '_blank', true);
  }
}

export default new AuthBrowser();
