import '@common/application-insights';

import React, { FC, ReactElement, ReactNode, useEffect, useRef } from 'react';

import GTM from '@apps/gtm/shared/multilabel/src/native';
import { App, AppState, URLOpenListenerEvent } from '@capacitor/app';
import { BackButtonListenerEvent } from '@capacitor/app/dist/esm/definitions';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ApplicationProvider } from '@common/application';
import env from '@common/env';
import { AppPreferences } from '@common/preferences';
import { SWRCacheProvider } from '@common/swr';
import { NotificationContextProvider } from '@components-next/Notification/Notification';
import { ErrorBoundary } from '@custom-components/native/ErrorBoundary';
import { GlobalLoader } from '@custom-components/native/LoadingPage/GlobalLoader';
import { DigitalCoreProvider } from '@dc/provider';
import { AppProps, NextPage, useRouter } from '@dxp-next';
import { useAuthToken } from '@native-auth/hooks/token';
import ReturnPathProvider from '@native-auth/providers/ReturnPathProvider';
import NativeApp from '@native-components/components/NativeApp';
import { SparkyProvider } from '@sparky/providers';
import { TrackingProvider, useTracking } from '@tracking';
import { Airship } from '@ua/capacitor-airship';
import { useSWRConfig } from 'swr';

import { businessUnit, label, name, i18n } from '../config';
import ErrorBoundaryPage from '../src/pages/ErrorBoundaryPage';
const { FIRST_TIME_OPEN } = AppPreferences;

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function setupAirship() {
  await Airship.takeOff({
    development: {
      appKey: env('AIRSHIP_DEV_KEY'),
      appSecret: env('AIRSHIP_DEV_SECRET'),
      logLevel: 'verbose',
    },
    production: {
      appKey: env('AIRSHIP_PRODUCTION_KEY'),
      appSecret: env('AIRSHIP_PRODUCTION_SECRET'),
      logLevel: 'error',
    },
    inProduction: env('ENVIRONMENT') === 'production',
    site: 'eu',
    urlAllowList: ['*'],
  }).finally(() => {
    Airship.push.enableUserNotifications();
    Airship.channel.getChannelId().then(channelId => {
      if (channelId) {
        Preferences.set({ key: 'channelId', value: channelId });
      }
    });
  });
}

const NativeDigitalCoreProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const tokenData = useAuthToken();
  return (
    <DigitalCoreProvider
      customerContext={{ customerId: tokenData?.customerId, accessToken: tokenData?.accessToken, accountId: 1 }}
      idToken={tokenData?.accessToken}
      label={label}
      businessUnit={businessUnit}>
      {children}
    </DigitalCoreProvider>
  );
};

function getPath() {
  if (typeof document === 'undefined') {
    return;
  }

  return window.location.pathname + window.location.search;
}

function getPageName(): string {
  return getPath() ?? '';
}

const SWRConfigClient: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { mutate } = useSWRConfig();

  // Refresh data when app is resumed from being idle
  useEffect(() => {
    const cachesToClear = ['getDeviceState'];

    App.addListener('appStateChange', (state: AppState) => {
      if (state?.isActive) {
        mutate(key => !!key && key.length > 0 && cachesToClear.includes(key[0]), undefined, { revalidate: true });
      }
    });
  }, [mutate]);

  return children;
};

const BeInsightsApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || (page => page);
  const router = useRouter();
  const { trackPageView, trackFirstTimeOpen } = useTracking();
  const previousPageRef = useRef('');
  const currentPageRef = useRef(router.asPath);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      // TODO: uncomment when Airship implementation is ready to be tested.
      // setupAirship();
    }
  }, []);

  useEffect(() => {
    // Fire page view event on page load
    trackPageView({ pageName: getPageName(), previousPage: '' });
  }, [trackPageView]);

  useEffect(() => {
    const handleRouteChange = () => {
      const pageName = getPageName();
      if (pageName !== previousPageRef.current) {
        trackPageView({ pageName, previousPage: previousPageRef.current });
        previousPageRef.current = pageName;
      }
    };

    // Fire page view event on route change
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router, trackPageView]);

  useEffect(() => {
    Preferences.get({ key: FIRST_TIME_OPEN }).then(({ value }) => {
      if (value !== 'true') {
        Preferences.set({ key: FIRST_TIME_OPEN, value: 'true' });
        trackFirstTimeOpen();
      }
    });
  }, [trackFirstTimeOpen]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({ style: Style.Light }).then(() => null);
    }

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const url = new URL(event.url);

      if (url.pathname.search('/deeplink') !== -1) {
        const path = url.pathname.replace('/deeplink', '');
        url.searchParams.set('item', encodeURIComponent(path));
      }

      window.location.assign(`/?${url.searchParams}`);
    });
  }, [router]);

  useEffect(() => {
    const deepLinkListener = (event: CustomEvent) => {
      router.push(event.detail);
    };

    document.addEventListener('deepLink', deepLinkListener as EventListenerOrEventListenerObject, false);

    return () => {
      document.removeEventListener('deepLink', deepLinkListener as EventListenerOrEventListenerObject, false);
    };
  }, [router]);

  useEffect(() => {
    const allowedRoutes = ['/', '/?item=/dashboard'];

    const minimizeApp = ({ canGoBack }: BackButtonListenerEvent) => {
      if (allowedRoutes.includes(decodeURIComponent(currentPageRef.current))) {
        App.minimizeApp();
      } else {
        if (canGoBack) {
          window.history.back();
        } else {
          App.exitApp();
        }
      }
    };

    App.addListener('backButton', minimizeApp);
  }, []);

  useEffect(() => {
    currentPageRef.current = router.asPath;
  }, [router.asPath]);

  return (
    <ApplicationProvider i18n={i18n} path={getPath()}>
      <TrackingProvider scope={name}>
        <SparkyProvider locale={i18n.locale}>
          <SWRCacheProvider>
            <SWRConfigClient>
              <GlobalLoader initiallyLoading={true}>
                <NativeDigitalCoreProvider>
                  <ReturnPathProvider ignorePaths={['/login/callback/', '/login/failed/']}>
                    <NotificationContextProvider>
                      <>
                        <GTM />
                        <NativeApp>
                          <ErrorBoundary fallback={<ErrorBoundaryPage />}>
                            {getLayout(<Component {...pageProps} />)}
                          </ErrorBoundary>
                        </NativeApp>
                      </>
                    </NotificationContextProvider>
                  </ReturnPathProvider>
                </NativeDigitalCoreProvider>
              </GlobalLoader>
            </SWRConfigClient>
          </SWRCacheProvider>
        </SparkyProvider>
      </TrackingProvider>
    </ApplicationProvider>
  );
};

export default BeInsightsApp;
