import { CapacitorHttp, HttpHeaders } from '@capacitor/core';

import { catchErrors, getHeaders, getRequestBody, getUrl } from './request-helpers';
import type { ApiRequestOptions, ApiResult } from './types';

/**
 * Request using capacitor-community http plugin
 * @param options The request options from the the service
 * @returns ApiResult['body']
 * @throws ApiError
 */
export async function request(options: ApiRequestOptions): Promise<ApiResult['body']> {
  const headers: HttpHeaders = {};
  getHeaders(options).forEach((value, key) => {
    headers[key] = value;
  });
  const url = getUrl(options);
  const response = await CapacitorHttp.request({
    url: url.toString(),
    method: options.method,
    headers,
    data: getRequestBody(options),
  });

  const result: ApiResult = {
    url,
    statusText: '',
    ok: response.status >= 200 && response.status <= 299,
    status: response.status,
    body: response.data,
    headers: response.headers as unknown as Headers,
  };

  catchErrors(options, result);

  return response.data;
}
