import { createDCHook, createMutationHook, collapseDataFromCall, collapseParams } from '../client';
import {
  getUsages,
  getUsagesBeV2,
  getMonthSummary,
  getServiceProductInsightsForCustomer,
  enableServiceProductInsightsForCustomer,
  disableServiceProductInsightsForCustomer,
  getServiceProductIsmaForCustomer,
  disableServiceProductIsmaForCustomer,
  enableServiceProductIsmaForCustomer,
  getMonthlyEnergyReportDocumentsByCustomer,
  getShortUrlAndExternalMandate,
  getInsightsDashboard,
  getMandateForCustomer,
  disableMandateForCustomer,
  enableMandateForCustomer,
  getCaptarUsageMonthPeaks,
  getCaptarMonthlyValues,
} from '../services/UsagesService';

export const useUsagesGetUsages = createDCHook(
  'getUsages',
  collapseDataFromCall(getUsages),
  { injectables: ['label', 'customerId', 'accountId'], flattenData: true },
  { maxAge: 60000 },
);

export const useUsagesGetUsagesBeV2 = createDCHook('getUsagesBeV2', collapseDataFromCall(getUsagesBeV2), {
  injectables: ['label', 'customerId', 'accountId'],
  flattenData: true,
});

export const useUsagesGetMonthSummary = createDCHook('getMonthSummary', collapseDataFromCall(getMonthSummary), {
  injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
  flattenData: true,
});

export const useUsagesGetServiceProductInsightsForCustomer = createDCHook(
  'getServiceProductInsightsForCustomer',
  collapseDataFromCall(getServiceProductInsightsForCustomer),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
  { maxAge: 15000 },
);

export const useUsagesEnableServiceProductInsightsForCustomer = createMutationHook(
  enableServiceProductInsightsForCustomer,
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: false },
);

export const useUsagesDisableServiceProductInsightsForCustomer = createMutationHook(
  disableServiceProductInsightsForCustomer,
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: false },
);

export const useUsagesGetServiceProductIsmaForCustomer = createDCHook(
  'getServiceProductIsmaForCustomer',
  collapseDataFromCall(getServiceProductIsmaForCustomer),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
  { maxAge: 15000 },
);

export const useUsagesDisableServiceProductIsmaForCustomer = createMutationHook(disableServiceProductIsmaForCustomer, {
  injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
  flattenData: false,
});

export const useUsagesEnableServiceProductIsmaForCustomer = createMutationHook(
  collapseDataFromCall(enableServiceProductIsmaForCustomer),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
);

export const useUsagesGetMonthlyEnergyReportDocumentsByCustomer = createDCHook(
  'getMonthlyEnergyReportDocumentsByCustomer',
  collapseDataFromCall(getMonthlyEnergyReportDocumentsByCustomer),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
);

export const useUsagesGetShortUrlAndExternalMandate = createMutationHook(
  collapseDataFromCall(
    collapseParams(
      collapseParams(getShortUrlAndExternalMandate, 'requestBody', 'data'),
      'data',
      'returnUrlSuccess',
      'returnUrlFailed',
    ),
  ),
  {
    injectables: ['label', 'customerId', 'accountId'],
    flattenData: true,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['returnUrlSuccess', 'returnUrlFailed'] },
    ],
  },
);

export const useUsagesGetInsightsDashboard = createDCHook(
  'getInsightsDashboard',
  collapseDataFromCall(getInsightsDashboard),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
  { maxAge: 15000 },
);

export const useUsagesGetMandateForCustomer = createDCHook(
  'getMandateForCustomer',
  collapseDataFromCall(getMandateForCustomer),
  { injectables: ['label', 'customerId', 'accountId', 'businessUnit'], flattenData: true },
);

export const useUsagesDisableMandateForCustomer = createMutationHook(disableMandateForCustomer, {
  injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
  flattenData: false,
});

export const useUsagesEnableMandateForCustomer = createMutationHook(collapseDataFromCall(enableMandateForCustomer), {
  injectables: ['label', 'customerId', 'accountId', 'businessUnit'],
  flattenData: true,
});

export const useUsagesGetCaptarUsageMonthPeaks = createDCHook(
  'getCaptarUsageMonthPeaks',
  collapseDataFromCall(getCaptarUsageMonthPeaks),
  { injectables: ['label', 'customerId'], flattenData: true },
);

export const useUsagesGetCaptarMonthlyValues = createDCHook(
  'getCaptarMonthlyValues',
  collapseDataFromCall(getCaptarMonthlyValues),
  { injectables: ['label', 'customerId'], flattenData: true },
);
